<template>
  <div>
    <template v-if="publishers.length">
      <div class="headings__container">
        <span class="publisher-heading">Published by</span>
        <span class="society-heading"> Society</span>
        <span class="admin-heading">Admin</span>
      </div>

      <section class="details__section">
        <div
          class="details__row"
          v-for="item in publishers"
          v-bind:key="item.collection_id"
        >
          <div class="publisher-name__col">
            <span
              class="publisher-name"
              v-bind:to="'/publishers/' + item.collection_id"
            >
              {{ item.publisher_name }}
            </span><br>
            <span class="contact-name">
              {{ item.email === "NULL" ? "-" : item.email }}<br>
              {{ item.phone === "NULL" ? "-" : item.phone }}
            </span>
          </div>

          <div class="society-name__col">
            <span class="society-name">
              {{ item.society }}
            </span>
          </div>

          <div class="admin__col">
            <span class="admin-name">
              {{ item.admin === "NULL" ? "-" : item.admin }}
            </span>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="details__section">
        <p>No publisher data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    publishers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "publisher"
      "admin";
  }

  @include tablet {
    display: flex;
  }
}

.society {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 20%;
    }
  }
}

.publisher {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: publisher;
    @include tablet {
      flex-basis: 40%;
    }
  }
}

.admin {
  &-heading {
    flex-basis: 40%;
  }

  &__col {
    grid-area: admin;
    @include tablet {
      flex-basis: 40%;
    }
  }

  &-name {
    @include mobile {
      color: $color-primary;
      font-size: 16px;
      font-weight: 400;
    }
  }
}
</style>
